.document-recognition-paper {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.document-recognition-capture {
  position: absolute !important;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  color: black !important;
  border: 1px solid black !important;
}

.document-recognition-togglecamera {
  display: none;
  position: absolute !important;
  bottom: 11%;
  left: 36%;
  transform: translateX(-50%);
  color: black !important;
  border: 1px solid black !important;
}

.overlay {
  position: relative;
  --border-style: 2px solid skyblue;
  --border-space: 5%;
}

.overlay-element {
  position: absolute;
  width: 25%; /* controls the length of the borders */
  height: 20%;  /* controls the height of the borders */
}

.overlay .top-left {
  border-left: var(--border-style);
  border-top: var(--border-style);
  top: var(--border-space);
  left: var(--border-space);
}

.overlay .top-right {
  border-right: var(--border-style);
  border-top: var(--border-style);
  top: var(--border-space);
  right: var(--border-space);
}

.overlay .bottom-left {
  border-left: var(--border-style);
  border-bottom: var(--border-style);
  bottom: var(--border-space);
  left: var(--border-space);
}

.overlay .bottom-right {
  border-right: var(--border-style);
  border-bottom: var(--border-style);
  bottom: var(--border-space);
  right: var(--border-space);
}

@media only screen and (min-width: 620px) and (max-width: 654.9px) {
  .document-recognition-paper {
    width: 95%;
  }
  .document-recognition-togglecamera {
    left: 14%;
  }
}

@media only screen and (min-width: 655px) and (max-width: 953.9px) {
  .document-recognition-paper {
    width: 92.5%;
  }
}

@media only screen and (min-width: 954px) and (max-width: 1259.9px) {
  .document-recognition-paper {
    width: 45%;
  }
}

@media only screen and (min-width: 1260px) and (max-width: 1680px) {
  .document-recognition-paper {
    width: 40%;
  }
}