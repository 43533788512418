/*.amplify-button {
  background-color: #3f51b4!important;
}

.amplify-button:hover {
  background-color: #25306c!important;
}*/

/*.liveness-detector-start {
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms!important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms!important;
  border-radius: 4px!important;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)!important;
}*/

.liveness-face-detector {
  padding-left: 0;
  width: 100%;
}

@media only screen and (min-width: 600px) and (max-width: 953px) {
  .liveness-face-detector {
    padding-left: clamp(0%, 20% - ((20 * (100vw - 600px)) / 1080), 20%);
    width: clamp(60%, 80% - ((20 * (100vw - 600px)) / 1080), 80%);
  }
}

@media only screen and (min-width: 954px) and (max-width: 1680px) {
  .liveness-face-detector {
    padding-left: 20%;
    width: 62%;
  }
}