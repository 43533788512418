:root {
  --font-size-scale-up-07: 3.583em;
  --font-size-scale-up-06: 2.986em;
  --font-size-scale-up-05: 2.488em;
  --font-size-scale-up-04: 2.074em;
  --font-size-scale-up-03: 1.728em;
  --font-size-scale-up-02: 1.44em;
  --font-size-scale-up-01: 1.2em;
  --font-size-scale-base: 1em;
  --font-size-scale-down-01: 0.833em;
  --font-size-scale-down-02: 0.694em;
  --font-size-scale-down-03: 0.579em;
}