.gv-br-button-start {
  margin-right: 0!important;
  background-color: var(--green-cool-vivid-50)!important;
  border: var(--surface-white-border-solid-lg)!important;
  text-transform: none!important;
}

.doubt-illustration-image {
  display: none;
}

@media (max-width: 599px) {
  .gv-br-button-init-form-item {
    width: 100%!important;
  }

  .gv-br-button-init-form-box {
    width: 100%;
  }
  
  .gv-br-button-init-form-stack {
    width: 100%;
  }
}

@media (min-width: 576px) {
  .gv-br-button-start {
    margin-right: var(--spacing-scale-7x);
  }
}

@media (min-width: 600px) {
  .doubt-illustration-image {
    display: block;
    margin-right: 0;
  }
}

@media (min-width: 900px) {
  .gv-br-button-start {
    margin-right: var(--spacing-scale-7x)!important;
    background-color: var(--green-cool-vivid-50)!important;
    border: var(--surface-white-border-solid-lg)!important;
    text-transform: none!important;
  }

  .doubt-illustration-image {
    display: block;
    margin-right: var(--spacing-scale-7x);
  }
}