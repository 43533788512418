@media (max-width: 991px) {
  .MuiStepper-root::after {
    background-color: transparent;
    bottom: 2px;
    color: var(--interactive);
    content: "\f7a4";
    display: block;
    font-family: "Font Awesome 5 Free", sans-serif;
    font-size: var(--switch-icon-size);
    font-weight: var(--font-weight-black);
    height: 1em;
    left: 50%;
    margin-left: -0.5em;
    position: absolute;
    top: unset;
    transition-delay: 0s;
    transition-duration: 0.25s;
    transition-property: all;
    transition-timing-function: linear;
    width: auto;
  }
}